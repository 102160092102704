import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "item",
    "transactions",
    "transactionPagination",
    "filterPanel"
  ]

  //
  // Getters
  //
  get assignmentModalContentWrapper() {
    return document.querySelector('[data-target="modal.content"]');
  }


  onDeleteSuccess(event) {
    let [data, status, xhr] = event.detail;

    // remove receipt card from view
    event.target.closest('[data-target="receipts.item"]').remove();
  }

  onDeleteError(event) {
    alert("Deleting receipt failed! Please try again.")
  }

  onLoadContentError(event) {
    alert("Loading transaction assignment view failed! Please try again.")
  }

  onLoadContentSuccess(event) {
    let [data, status, xhr] = event.detail;

    // load modal layout
    this.assignmentModalContentWrapper.innerHTML = data.partial_index;

    // load filter panel
    this.filterPanelTarget.innerHTML = data.partial_filter_panel;

    // focus filter panel main form field
    this.filterPanelTarget.querySelector(`[data-js-filter-panel-main-form-field]`).focus();

    // load transactions list into transactionsTarget
    this.transactionsTarget.innerHTML = data.partial_transactions;

    // load pagination
    this.transactionPaginationTarget.innerHTML = data.partial_transactions_pagination;
  }

  onFilterError(event) {
    alert("Filtering assignment transactions failed! Please try again.")
  }

  onFilterSuccess(event) {
    let [data, status, xhr] = event.detail;

    // load filter panel
    this.filterPanelTarget.innerHTML = data.partial_filter_panel;

    // focus filter panel main form field
    let mainFormField = this.filterPanelTarget.querySelector(`[data-js-filter-panel-main-form-field]`);
    mainFormField.focus();
    // this is how to move the cursor to the last position within a form field
    // https://stackoverflow.com/a/56719955/5240585
    mainFormField.setSelectionRange(-1, -1);

    // load transactions list into transactionsTarget
    this.transactionsTarget.innerHTML = data.partial_transactions;

    // load pagination
    this.transactionPaginationTarget.innerHTML = data.partial_transactions_pagination;
  }

  onLoadMoreError(event) {
    alert("Loading more transactions to assign failed! Please try again.")
  }

  onLoadMoreSuccess(event) {
    let [data, status, xhr] = event.detail;

    // load transactions list into transactionsTarget
    this.transactionsTarget.insertAdjacentHTML('beforeend', data.partial_transactions);

    // load pagination
    this.transactionPaginationTarget.innerHTML = data.partial_transactions_pagination;
  }

  onAssignError(event) {
    alert("Assigning receipt to transaction failed! Please try again.")
  }

  onAssignSuccess(event) {
    let [data, status, xhr] = event.detail;

    console.log("Assigning transaction to receipt ...")
    // TODO: rails does a JS request. change to JSON

    // close modal
    // remove receipt card from list
  }
}
