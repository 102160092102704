import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "tableColumnToggle",
    "sidePanelDetailsWrapper",
    "closeLink",
    "txnGridWrapper",
    "txnTableWrapper",
    "txnSidePanelWrapper",
    "txnRow",
    "viewIndicator",
    "viewLink"
  ]

  open(event) {
    event.preventDefault()

    // hide title column in table to free up space
    this.tableColumnToggleTargets.forEach( target => {
      target.classList.add('hidden');
    })

    // targetElement represents the clicked 'tr' link
    let clickedTransactionRow = event.target.closest('tr');

    let currentDetailsSelector = clickedTransactionRow.getAttribute('data-show-txn-details');
    let currentDetailsPartial = this.sidePanelDetailsWrapperTargets.filter(element => element.id == currentDetailsSelector)[0];

    // write currentDetailsSelector to hide side panel button
    this.closeLinkTarget.setAttribute('data-hide-txn-details', currentDetailsSelector);

    // hide all txn detail wrappers to ensure blank slate
    this.sidePanelDetailsWrapperTargets.forEach( target => {
      target.classList.add('hidden');
    })

    // then show partial for current selected txn details
    currentDetailsPartial.classList.remove("hidden");

    // remove colspan to make space to side panel
    this.txnTableWrapperTarget.classList.remove("col-span-6");
    this.txnTableWrapperTarget.classList.add("col-span-4");

    // add blue background to current current txnRow
    this.txnRowTargets.forEach(target => {
      // remove blue backgrounds from all txnRow
      target.classList.remove("bg-blue-100");

      // check txnRow if it's unprocessed; if so, add original background and hover effect
      if(target.hasAttribute('data-is-unprocessed')) {
        target.classList.add("bg-yellow-200");
      }
    });

    // manage hover and bg effect for current clicked row
    if(clickedTransactionRow.hasAttribute('data-is-unprocessed')) {
      console.log("data is unprocessed");
      clickedTransactionRow.classList.remove("bg-yellow-200");
    }
    clickedTransactionRow.classList.add("bg-blue-100");

    // finally show the side panel wrapper to make side panel item visible
    this.txnSidePanelWrapperTarget.classList.remove("hidden");

    // show all view links
    this.viewLinkTargets.forEach( target => {
      target.classList.remove('hidden');
    });

    // hide all view indicator icons
    this.viewIndicatorTargets.forEach( target => {
      target.classList.add('hidden');
    });

    // find transaction row view link and indicator
    let currentViewLink = clickedTransactionRow.querySelector('[data-target="transaction-side-panel-state.viewLink"]');
    let currentViewIndicator = clickedTransactionRow.querySelector('[data-target="transaction-side-panel-state.viewIndicator"]');

    // hide clicked view link
    currentViewLink.classList.add("hidden");
    // show view indicator icon of clicked view link
    currentViewIndicator.classList.remove("hidden");
  }

  close(event) {
    event.preventDefault();

    // delete indicator of hide link
    this.closeLinkTarget.setAttribute('data-hide-txn-details', '');

    // hide all txn detail wrappers to ensure blank slate
    this.sidePanelDetailsWrapperTargets.forEach( target => {
      target.classList.add('hidden');
    });

    // hide the side panel wrapper to make side panel item visible
    this.txnSidePanelWrapperTarget.classList.add("hidden");

    // remove colspan to make space to side panel
    this.txnTableWrapperTarget.classList.remove("col-span-4");
    this.txnTableWrapperTarget.classList.add("col-span-6");

    // show hideable table column to restore table state
    this.tableColumnToggleTargets.forEach( target => {
      target.classList.remove('hidden');
    });

    // show all view links
    this.viewLinkTargets.forEach( target => {
      target.classList.remove('hidden');
    });

    // hide all view indicator icons
    this.viewIndicatorTargets.forEach( target => {
      target.classList.add('hidden');
    });

    this.txnRowTargets.forEach( target => {
      target.classList.remove("bg-blue-100");

      if(target.hasAttribute('data-is-unprocessed')) {
        target.classList.add("bg-yellow-200");
      }
    });
  }
}
