import Dropzone from "dropzone";
import { Controller } from "stimulus";
import {
  getMetaValue,
} from "helpers";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = this._createDropZone();
    this._hideFileInput();
    this._bindEvents();
    Dropzone.autoDiscover = false;
  }

  //
  // Getters
  //
  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    let form = this.inputTarget.closest('form')
    return form.getAttribute('action')
  }

  get maxFiles() {
    return this.data.get("maxFiles") || null; // null == unlimited
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 5;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

  get paramName() {
    return this.inputTarget.getAttribute("name")
  }

  get receiptsStateController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "transaction-side-panel-receipts-state")
  }

  get parallelUploads() {
    return this.data.get('parallelUploads') || 1
  }

  get transactionRow() {
    return document.getElementById(this.data.get('transactionRowSelectorId'))
  }

  get targetWrapper() {
    return document.getElementById(this.data.get('targetSelectorId'))
  }

  get transactionDetails() {
    return document.getElementById(this.data.get('transactionDetailsSelectorId'));
  }

  //
  // Private methods
  //

  _createDropZone() {
    return new Dropzone(this.element, {
      url: this.url,
      headers: this.headers,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFileSize,
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: this.addRemoveLinks,
      paramName: this.paramName,
      parallelUploads: this.parallelUploads
    })
  }

  _hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";

    let formLabel = this.inputTarget.parentNode.querySelector('label');
    formLabel.style.display = 'none';
  }

  _bindEvents() {
    this.dropZone.on("addedfile", file => {
      console.log("[dropzone] added file ... ");
    });

    this.dropZone.on("removedfile", file => {
      console.log("[dropzone] removed file ... ");
    });

    this.dropZone.on("canceled", file => {
      console.log("[dropzone] cancelled ... ");
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("success", (file, response) => {
      console.log("[dropzone] File uploaded ... ");

      // re-render receipts list
      this.targetWrapper.innerHTML = response.partial_side_panel_receipts_list

      // replace transaction row which is outside of the side panel controller
      this.transactionRow.outerHTML = response.partial_transaction_row

      // hide columns in transaction row
      this._hideTransactionRowColumns();

      // replace transaction details in side panel
      this.transactionDetails.innerHTML = response.partial_transaction_details;

      // show transaction row indicator icon
      this._showTransactionRowIndicator();

      // manage current row's highlighting effect
      this._manageTransactionRowHighlighting();

      // fetch / find transactionSidePanelReceiptsStateController and call toggle
      // let receiptsStateController = this.application.getControllerForElementAndIdentifier(targetWrapper, "collapsible-panel-state")
      // receiptsStateController.toggle();
    });

    this.dropZone.on("complete", file => {
      console.log("[dropzone] File uploaded successfully ... ")
      console.log(file)
    });

    this.dropZone.on("queuecomplete", file => {
      console.log("[dropzone] All files of queue completed ... ")

      // Remove all files from dropzone which triggers reset for dropzone
      this.dropZone.removeAllFiles(true);
    });
  }

  _hideTransactionRowColumns() {
    let hideableColumns = this.transactionRow.querySelectorAll('[data-target="transaction-side-panel-state.tableColumnToggle"]')
    hideableColumns.forEach(element => element.classList.add('hidden'))
  }

  _showTransactionRowIndicator() {
    // find transaction row view link and indicator
    let transactionRowViewLink = this.transactionRow.querySelector('[data-target="transaction-side-panel-state.viewLink"]');
    let transactionRowViewIndicator = this.transactionRow.querySelector('[data-target="transaction-side-panel-state.viewIndicator"]');

    // hide clicked view link
    transactionRowViewLink.classList.add("hidden");

    // show view indicator icon of clicked view link
    transactionRowViewIndicator.classList.remove("hidden");
  }

  _manageTransactionRowHighlighting(){
    this.transactionRow.classList.remove('bg-yellow-200');
    this.transactionRow.classList.add('bg-blue-100');
  }
}
