import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "showIcon",
    "hideIcon",
    "collapsibleElement"
  ]

  toggle(event) {
    event && event.preventDefault();

    // toggle icon chevron up or down
    this.showIconTarget.classList.toggle("hidden");
    this.hideIconTarget.classList.toggle("hidden");

    // toggle collapsible element
    this.collapsibleElementTarget.classList.toggle("hidden");
  }
}
