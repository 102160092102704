import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "trigger" ]

  loading(event) {
    let targetElement = event.target

    // for a form event.target is the form itself.
    // In this case we need to find the submit button to set the loading indicator
    if(event.target.nodeName == "FORM" ) {
      targetElement = event.target.querySelector('button[type="submit"]')
    }

    targetElement.textContent = "Loading ..."
  }
}
