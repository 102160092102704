import Dropzone from "dropzone";
import { Controller } from "stimulus";
import {
  getMetaValue,
} from "helpers";

export default class extends Controller {
  static targets = [
    "input"
  ];

  connect() {
    this.dropZone = this._createDropZone();
    this._hideFileInput();
    this._bindEvents();
    Dropzone.autoDiscover = false;
  }

  //
  // Getters
  //
  get dropzoneTargetWrapper() {
    // naming is by convention "data-<dashed controller name>-target"
    return document.querySelector('[data-receipts-dropzone-target]');
  }

  get blankSlateTargetWrapper() {
    return document.querySelector('[data-receipts-blank-slate-target]');
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    let form = this.inputTarget.closest('form')
    return form.getAttribute('action')
  }

  get maxFiles() {
    return this.data.get("maxFiles") || null; // null == unlimited
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 5;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

  get paramName() {
    return this.inputTarget.getAttribute("name")
  }

  get parallelUploads() {
    return this.data.get('parallelUploads') || 1
  }

  //
  // Private methods
  //

  _createDropZone() {
    return new Dropzone(this.element, {
      url: this.url,
      headers: this.headers,
      maxFiles: this.maxFiles,
      maxFilesize: this.maxFileSize,
      acceptedFiles: this.acceptedFiles,
      addRemoveLinks: this.addRemoveLinks,
      paramName: this.paramName,
      parallelUploads: this.parallelUploads
    })
  }

  _hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";

    let formLabel = this.inputTarget.parentNode.querySelector('label');
    formLabel.style.display = 'none';
  }

  _bindEvents() {
    this.dropZone.on("addedfile", file => {
      console.log("[dropzone] added file ... ");
    });

    this.dropZone.on("removedfile", file => {
      console.log("[dropzone] removed file ... ");
    });

    this.dropZone.on("canceled", file => {
      console.log("[dropzone] cancelled ... ");
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("success", (file, response) => {
      console.log("[dropzone] File uploaded ... ");

      // add new receipt card to beginning of receiptList
      this.dropzoneTargetWrapper.insertAdjacentHTML('afterbegin', response.partial_receipt_card);

      // hide receipts blank slate graphic
      this.blankSlateTargetWrapper.classList.add('hidden');
    });

    this.dropZone.on("complete", file => {
      console.log("[dropzone] File uploaded successfully ... ")
    });

    this.dropZone.on("queuecomplete", file => {
      console.log("[dropzone] All files of queue completed ... ")

      // Remove all files from dropzone which triggers reset for dropzone
      this.dropZone.removeAllFiles(true);
    });
  }
}
