import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "txnList",
    "dateRangeSelect",
    "dateRangeText",
  ]

  onFilterError(event) {
    alert("Loading transaction list failed! Please try again.")
  }

  onFilterSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.txnListTarget.innerHTML = xhr.response;
  }

  onDateRangeChange(event) {
    let options = this.dateRangeSelectTarget.options
    let selectedOption = options[options.selectedIndex]

    if (selectedOption.value == "custom") {
      this.dateRangeTextTarget.removeAttribute("disabled");
      this.dateRangeTextTarget.focus();
    } else {
      this.dateRangeTextTarget.setAttribute("disabled", true);
    }
  }
}
