import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "cancelLink",
    "formWrapper",
    "receipts",
    "transactionDetails"
  ]

  onLoadFormError(event) {
    alert("Loading transaction edit view failed! Please try again.")
  }

  onLoadFormSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.transactionDetailsTarget.innerHTML = xhr.response;
  }

  onCancelFormError(event) {
    alert("Cannot cancel transaction form edit! Please try again.")
  }

  onCancelFormSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.transactionDetailsTarget.innerHTML = xhr.response;
  }

  onEditFormSuccess(event) {
    let [data, status, xhr] = event.detail;

    // replace transaction details
    this.transactionDetailsTarget.innerHTML = data.partial_transaction_details;

    // replace transaction row which is outside of the side panel controller
    this.transactionRow.outerHTML = data.partial_transaction_row

    // hide columns in transaction row
    this._hideTransactionRowColumns();

    // show transaction row indicator icon
    this._showTransactionRowIndicator();

    // manage current row's highlighting effect
    this._manageTransactionRowHighlighting();
  }

  onEditFormError(event) {
    let [data, status, xhr] = event.detail;
    this.formWrapperTarget.innerHTML = xhr.response;
  }

  onDeleteReceiptSuccess(event) {
    let [data, status, xhr] = event.detail;

    // replace list of receipts
    this.receiptsTarget.innerHTML = data.partial_side_panel_receipts_list

    // replace transaction row which is outside of the side panel controller
    this.transactionRow.outerHTML = data.partial_transaction_row

    // replace transaction details
    this.transactionDetailsTarget.innerHTML = data.partial_transaction_details;

    // hide columns in transaction row
    this._hideTransactionRowColumns();

    // show transaction row indicator icon
    this._showTransactionRowIndicator();

    // manage current row's highlighting effect
    this._manageTransactionRowHighlighting();
  }

  onDeleteReceiptError(event) {
    alert("Deleting receipt failed! Please try again.")
  }

  onMarkPaperlessError(event) {
    alert("Mark transaction as paperless failed! Please try again.")
  }

  onMarkPaperlessSuccess(event) {
    let [data, status, xhr] = event.detail;

    // replace transaction details
    this.transactionDetailsTarget.innerHTML = data.partial_transaction_details;

    // replace transaction row which is outside of the side panel controller
    this.transactionRow.outerHTML = data.partial_transaction_row

    // hide columns in transaction row
    this._hideTransactionRowColumns();

    // show transaction row indicator icon
    this._showTransactionRowIndicator();

    // manage current row's highlighting effect
    this._manageTransactionRowHighlighting();
  }

  onUnmarkPaperlessError(event) {
    alert("Unmark transaction as paperless failed! Please try again.")
  }

  onUnmarkPaperlessSuccess(event) {
    let [data, status, xhr] = event.detail;

    // replace transaction details
    this.transactionDetailsTarget.innerHTML = data.partial_transaction_details;

    // replace transaction row which is outside of the side panel controller
    this.transactionRow.outerHTML = data.partial_transaction_row

    // hide columns in transaction row
    this._hideTransactionRowColumns();

    // show transaction row indicator icon
    this._showTransactionRowIndicator();

    // manage current row's highlighting effect
    this._manageTransactionRowHighlighting();
  }

  onMarkNonBusinessError(event) {
    alert("Mark transaction as non-business failed! Please try again.")
  }

  onMarkNonBusinessSuccess(event) {
    let [data, status, xhr] = event.detail;

    // replace transaction details
    this.transactionDetailsTarget.innerHTML = data.partial_transaction_details;

    // replace transaction row which is outside of the side panel controller
    this.transactionRow.outerHTML = data.partial_transaction_row

    // hide columns in transaction row
    this._hideTransactionRowColumns();

    // show transaction row indicator icon
    this._showTransactionRowIndicator();

    // manage current row's highlighting effect
    this._manageTransactionRowHighlighting();
  }

  onUnmarkNonBusinessError(event) {
    alert("Unmark transaction as non-business failed! Please try again.")
  }

  onUnmarkNonBusinessSuccess(event) {
    let [data, status, xhr] = event.detail;

    // replace transaction details
    this.transactionDetailsTarget.innerHTML = data.partial_transaction_details;

    // replace transaction row which is outside of the side panel controller
    this.transactionRow.outerHTML = data.partial_transaction_row

    // hide columns in transaction row
    this._hideTransactionRowColumns();

    // show transaction row indicator icon
    this._showTransactionRowIndicator();

    // manage current row's highlighting effect
    this._manageTransactionRowHighlighting();
  }

  //
  // Private methods
  //
  _hideTransactionRowColumns() {
    let hideableColumns = this.transactionRow.querySelectorAll('[data-target="transaction-side-panel-state.tableColumnToggle"]')
    hideableColumns.forEach(element => element.classList.add('hidden'))
  }

  _showTransactionRowIndicator() {
    // find transaction row view link and indicator
    let transactionRowViewLink = this.transactionRow.querySelector('[data-target="transaction-side-panel-state.viewLink"]');
    let transactionRowViewIndicator = this.transactionRow.querySelector('[data-target="transaction-side-panel-state.viewIndicator"]');

    // hide clicked view link
    transactionRowViewLink.classList.add("hidden");

    // show view indicator icon of clicked view link
    transactionRowViewIndicator.classList.remove("hidden");
  }

  _manageTransactionRowHighlighting(){
    this.transactionRow.classList.remove('bg-yellow-200');
    this.transactionRow.classList.add('bg-blue-100');
  }

  //
  // Getters
  //
  get transactionRow() {
    return document.getElementById(this.data.get('transactionRowSelectorId'));
  }
}
